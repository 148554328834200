import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
        return Promise.reject(err)
    })
}

Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'index', component: () => import('@/views/index'), children: [
        {path: 'main/tip/:idx', name: 'main/tip/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'main/lab/:idx', name: 'main/lab/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'main/ggomunity/new', name: 'main/ggomunity/new', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
        {path: 'main/ggomunity/:idx', name: 'main/ggomunity/detail', component: () => import('@/views/ggomunity/detail'), children: [
            {path: 'modify', name: 'main/ggomunity/modify', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
            {path: 'report', name: 'main/ggomunity/report', component: () => import('@/views/board/report'), meta: { auth: true }},
        ]},
    ]},
    {path: '/login', name: 'login', component: () => import('@/views/login'), meta: { noAuth: true, layout: 'NoLayout' }},
    {path: '/login/:sns', name: 'login/:sns', component: () => import('@/views/login'), meta: { noAuth: true, layout: 'NoLayout' }},
    {path: '/sleep', name: 'sleep', component: () => import('@/views/sleep'), meta: { layout: 'NoLayout' }},
    {path: '/join', name: 'join', component: () => import('@/views/join'), meta: { noAuth: true, layout: 'NoLayout' }},
    {path: '/join/exist', name: 'join/exist', component: () => import('@/views/join/exist'),  meta: { noAuth: true, layout: 'NoLayout' }},
    {path: '/verify/phone1', name: 'verify/phone1', component: () => import('@/views/verify/phone1'), meta: { slide: true, slideCallBack: 0, layout: 'NoLayout' }},
    {path: '/verify/phone2', name: 'verify/phone2', component: () => import('@/views/verify/phone2'), meta: { slide: true, slideCallBack: 1, layout: 'NoLayout' }},
    {path: '/join/email', name: 'join/email', component: () => import('@/views/join/email'), meta: { noAuth: true, slideCallBack: 2, slide: true, layout: 'NoLayout' }},
    // {path: '/join/sns', name: 'join/sns', component: () => import('@/views/join/sns'), meta: { noAuth: true, slideCallBack: 2, slide: true, layout: 'NoLayout' }},
    {path: '/join/complete', name: 'join/complete', component: () => import('@/views/join/complete'), meta: { layout: 'NoLayout' }},
    {path: '/find/id', name: 'find/id', component: () => import('@/views/find/id'), meta: { noAuth: true, slide: true, layout: 'NoLayout' }},
    {path: '/find/password', name: 'find/password', component: () => import('@/views/find/password'), meta: { noAuth: true, slide: true, layout: 'NoLayout' }},
    {path: '/newPassword', name: 'newPassword', component: () => import('@/views/newPassword'), meta: { noAuth: true, layout: 'NoLayout' }},

    {path: '/menu', name: 'menu', component: () => import('@/views/menu')},
    {path: '/notification', name: 'notification', component: () => import('@/views/notification')},
    {path: '/modify/info', name: 'modify/info', component: () => import('@/views/modify/info')},
    {path: '/modify/password', name: 'modify/password', component: () => import('@/views/modify/password')},
    {path: '/withdrawal', name: 'withdrawal', component: () => import('@/views/withdrawal')},

    {path: '/tip', name: 'tip', component: () => import('@/views/board/tip'), children: [
        {path: ':idx', name: 'tip/detail', component: () => import('@/views/board/detailWithComments')},
    ]},

    {path: '/lab', name: 'lab', component: () => import('@/views/board/lab'), children: [
        {path: ':idx', name: 'lab/detail', component: () => import('@/views/board/detailWithComments')},
    ]},

    {path: '/md', name: 'md', component: () => import('@/views/md/list'), children: [
        {path: ':id', name: 'md/listById', component: () => import('@/views/md/listById'), children: [
            {path: ':idx', name: 'md/listById/:idx', component: () => import('@/views/md/detail')},
        ]},
    ]},

    {path: '/ggomunity', name: 'ggomunity', component: () => import('@/views/ggomunity/list'), children: [
        {path: 'new', name: 'ggomunity/new', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
        {path: ':idx', name: 'ggomunity/detail', component: () => import('@/views/ggomunity/detail'), children: [
            {path: 'modify', name: 'ggomunity/modify', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
            {path: 'report', name: 'ggomunity/report', component: () => import('@/views/board/report'), meta: { auth: true }},
        ]},
    ]},

    {path: '/event', name: 'event', component: () => import('@/views/event/list'), children: [
        {path: ':idx', name: 'event/detail', component: () => import('@/views/event/detail')},
    ]},

    {path: '/notice', name: 'notice', component: () => import('@/views/board/notice'), children: [
        {path: ':idx', name: 'notice/detail', component: () => import('@/views/board/detail')},
    ]},

    {path: '/inquiry', name: 'inquiry', component: () => import('@/views/inquiry/list'), meta: { auth: true }, children: [
        {path: 'new', name: 'inquiry/new', component: () => import('@/views/inquiry/save')},
        {path: ':idx', name: 'inquiry/detail', component: () => import('@/views/inquiry/detail'), children: [
            {path: 'modify', name: 'inquiry/modify', component: () => import('@/views/inquiry/save')},
        ]},
    ]},

    {path: '/faq', name: 'faq', component: () => import('@/views/board/faq'), children: [
        {path: ':idx', name: 'faq/detail', component: () => import('@/views/board/detail')},
    ]},

    {path: '/search', name: 'search', component: () => import('@/views/search'), children: [
        {path: 'tip/:idx', name: 'search/tip/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'lab/:idx', name: 'search/lab/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'ggomunity/:idx', name: 'search/ggomunity/detail', component: () => import('@/views/ggomunity/detail'), children: [
            {path: 'modify', name: 'search/ggomunity/modify', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
            {path: 'report', name: 'search/ggomunity/report', component: () => import('@/views/board/report'), meta: { auth: true }},
        ]},
    ]},

    {path: '/my/posts/:type', name: 'my/posts/:type', component: () => import('@/views/mypage/posts'), meta: { auth: true }, children: [
        {path: 'tip/:idx', name: 'my/posts/tip/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'lab/:idx', name: 'my/posts/lab/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'ggomunity/:idx', name: 'my/posts/ggomunity/detail', component: () => import('@/views/ggomunity/detail'), children: [
            {path: 'modify', name: 'my/posts/ggomunity/modify', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
        ]},
    ]},

    {path: '/my/activity/:type', name: 'my/activity/:type', component: () => import('@/views/mypage/activity'), meta: { auth: true }, children: [
        {path: 'tip/:idx', name: 'my/activity/tip/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'lab/:idx', name: 'my/activity/lab/detail', component: () => import('@/views/board/detailWithComments')},
        {path: 'ggomunity/:idx', name: 'my/activity/ggomunity/detail', component: () => import('@/views/ggomunity/detail'), children: [
            {path: 'modify', name: 'my/activity/ggomunity/modify', component: () => import('@/views/ggomunity/save'), meta: { auth: true }},
            {path: 'report', name: 'my/activity/ggomunity/report', component: () => import('@/views/board/report'), meta: { auth: true }},
        ]},
    ]},

    {path: '/mypet', name: 'mypet', component: () => import('@/views/mypet/index'), meta: { auth: true, layout: 'NoLayout' },},
    {path: '/mypet/register', name: 'mypet/register', component: () => import('@/views/mypet/register1'), meta: { auth: true, layout: 'NoLayout' }},
    {path: '/mypet/complete', name: 'mypet/complete', component: () => import('@/views/mypet/complete'), meta: { auth: true, layout: 'NoLayout' }},
    {path: '/mypet/modify/:idx', name: 'mypet/modify', component: () => import('@/views/mypet/modify'), meta: { auth: true, layout: 'NoLayout' }},
    {path: '/mypet/chart1/:idx', name: 'mypet/chart1', component: () => import('@/views/mypet/chart1'), meta: { auth: true, layout: 'MyPet' }, children: [
        {path: 'weight/:weIdx', name: 'mypet/chart1/weightSave', component: () => import('@/views/mypet/weightSave'), meta: { auth: true, layout: 'MyPet' },},
    ]},
    {path: '/mypet/chart2/:idx', name: 'mypet/chart2', component: () => import('@/views/mypet/chart2'), meta: { auth: true, layout: 'MyPet' }, children: [
        {path: 'vac/:vacIdx/:vrIdx', name: 'mypet/chart2/vacSave', component: () => import('@/views/mypet/vacSave'), meta: { auth: true, layout: 'MyPet' },},
    ]},
    {path: '/mypet/chart3/:idx', name: 'mypet/chart3', component: () => import('@/views/mypet/chart3'), meta: { auth: true, layout: 'MyPet' }, children: [
        {path: 'lab/:idx', name: 'mypet/chart3/lab/detail', component: () => import('@/views/board/detailWithComments')},
    ]},

    {path: '*', name: '404', component: () => import('@/views/404'), meta: { layout: 'NoLayout' }},
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        const toPath = to.path.split('/')[1]
        const fromPath = from.path.split('/')[1]
        const isMain = (from.path === '/' && to.name.startsWith('main/')) || (to.path === '/' && from.name.startsWith('main/'))
        if (toPath !== fromPath && !isMain) return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    const isLogin = localStorage.getItem('auth')
    if (to.meta.auth && !isLogin) {
        console.log('인증이 필요합니다')
        next('/login')
        return false
    }
    if (to.meta.noAuth && isLogin && to.path !== '/newPassword') {
        console.log('이미 로그인된 상태입니다')
        next('/')
        return false
    }
    next()
});

export default router
