<template>
  <div id="app" class="wrap">
    <globalHeader/>
      <slot/>
    <globalFooter/>
    <fix-menu />
  </div>
</template>
<script>
import GlobalHeader from "@/components/common/Header"
import GlobalFooter from "@/components/common/Footer"
import FixMenu from "@/components/common/FixMenu"
export default {
  components: {GlobalHeader, GlobalFooter, FixMenu},
  data() {
    return {
    }
  },
}
</script>