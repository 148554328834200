<template>
  <div>
    <div class="ddiBanner" v-if="bannerList7015.length > 0">
      <div class="ddiBanner-inner vertical">
        <VueSlickCarousel v-if="bannerList7015" v-bind="this.topBannerSettings">
          <div v-for="(item, index) in bannerList7015" :key="`banner-7015-${index}`">
            <div @click="goBannerLink(item.link, item.linkTarget)"><img :src="item.img"></div>
          </div>
        </VueSlickCarousel>
      </div>
      <div class="close" @click="closeDdiBanner"></div>
    </div>

    <div class="layout-header">
      <div class="myPetProfile" @click="mypetSelect" >
          <div class="profile-img">
            <div class="profile-img-bg">
              <image-alt v-if="mainPet.thumbnail" :src="mainPet.thumbnail" altType="profile"/>
            </div>
          </div>
          <div class="petname " v-if="mainPet.name" :class="{mypet : mypet == true }">{{mainPet.name}}</div>
          <div class="petname " v-else>반려동물 등록</div>
      </div> 
      
      <div class="btn-wrap">
        <button @click="$router.push('/search')" class="icon-img icon-search">검색</button>

      </div>
    </div>
    <mypet-select  @save="save" @close="close" v-if="isMypetSelectPopup" />
  </div>
</template>
<script>
import carouselMixin from "@/mixins/carouselMixin"
import bannerMixin from "@/mixins/bannerMixin"
import { mapGetters, mapActions } from 'vuex'
import MypetSelect from '@/components/mypet/MypetSelect'
export default {
  components: { MypetSelect },
  mixins: [carouselMixin, bannerMixin],
  data() {
    return {
      bannerList7015: [], // 상단 띠 배너(M)
      isMypetSelectPopup: false,
      mypetName:null,
      mypet:true,
      
      mainPet: {},
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
      getAuthToken: 'session/getAuthToken',
      getId: 'session/getId',
      getEmail: 'session/getEmail',
      getName: 'session/getName',
      getUrl: 'session/getUrl',
      getTarget: 'session/getTarget',
      getPhone: 'session/getPhone',
    }),
    isLoginStatus() {
      return this.isLogin;
    },
  },
  created() {
    this.setBanner7015() // 상단 띠 배너(M)
    this.getMainPet()
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getMainPet() {
      const { result, data } = await this.$api.getMainPet()
      if (result === 'success') this.mainPet = data
    },
    async getWordFilter() {
      const { result, data } = await this.$api.getWordFilter()
      if (result === 'success') this.setWordFilter({ wordFilter: data })
    },
    async setBanner7015() {
      const handleScroll = () => {
        const ddiBanner = document.querySelector('.ddiBanner');
        const header = document.querySelector('.layout-header');
        if (header) {
          let ddiBannerHeight = 0
          if (ddiBanner) ddiBannerHeight = ddiBanner.clientHeight
          const currentYOffset = window.scrollY;
          if(currentYOffset <= ddiBannerHeight){
            //header.classList.remove('fix')
          } else {
            //header.classList.add('fix')
          }
        }
      }
      this.bannerList7015 = this.$cookie.get('ggolab-banner-7015') ? [] : await this.getBanner(7015)
      if (this.bannerList7015) {
        setTimeout(() => {
          handleScroll()
          window.addEventListener('scroll', handleScroll)
        }, 100)

        setTimeout(() => {
          this.setHeightForVerticalSlide()
        }, 300)
      }
    },
    closeDdiBanner() {
      this.bannerList7015 = []
      const expires = '1d'
      const domain = location.host.split(':')[0]
      this.$cookie.set('ggolab-banner-7015', true, { domain, expires })
    }, 
    mypetSelect() {
      if (!this.isLogin) {
        this.setMsgPopup({
          type: 'alert',
          message: this.$msg('login.need'),
          okay: 'GO_LOGIN',
          okayCallback: () => {
            this.setMsgPopup()
            localStorage.setItem('loginBeforePage', this.$route.fullPath)
            this.$router.push('/login')
          }
        })
      }
      else {
        if (this.mainPet.name) {
          document.body.style.overflowY ="hidden"
          this.isMypetSelectPopup = true
        } else {
          this.$router.push('/mypet/register')
        }
      }
    },
    close() {
      document.body.style.overflowY ="scroll"
      this.isMypetSelectPopup = false;
    },
    save() {
      this.getMainPet()
      this.close()
    },
    goBannerLink(link, target) {
      if (window.ReactNativeWebView) {
        if (link.startsWith(location.origin)) {
          this.$router.push(link.split(location.origin)[1])
        }
        else {
          window.open(link, '_target')
        }
      } else {
        window.open(link, target)
      }
    },
  }
}
</script>
