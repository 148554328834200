<template>
  <div class="layout-footer">
    <p>
      {{ info.siteName }} ㅣ 대표이사 {{ info.ceoName }}  ㅣ  {{ info.addr }}<br>
      사업자번호: {{ info.bizNum && info.bizNum.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3') }}  ㅣ  통신판매업신고: {{ info.comSaleNum }}<br>
      Tel : {{ info.comTel }}  ㅣ  E-mail : {{ info.comEmail }}
    </p>
    <p class="copy">v1.6.2©2021. dreaminsight. All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: 'globalFooter',
  data() {
    return {
      info: {
        siteName: '',
        comName: '',
        bizNum: '',
        comSalemNum: '',
        ceoName: '',
        comTel: '',
        comEmail: '',
        addr: '',
        bizHour: '',
      },
    }
  },
  created() {
    this.getCompanyInfo()
  },
  methods:{
    async getCompanyInfo() {
      const { result, data } = await this.$api.getCompanyInfo()
      if (result === 'success') this.info = data
    }
  }
}
</script>
