export default {
  // 유효성검사(Validation)
  "required.input": "필수 입력 항목입니다.",
  "required.select": "필수 선택 항목입니다.",
  "required.agree": "필수 동의 항목입니다.",
  "email.check": "이메일 형식에 맞지 않습니다.",
  "email.exist": "이미 등록된 아이디 입니다.",
  "email.exist.naver": "naver 간편 가입하기로 가입된 내역이 존재합니다. 간편 로그인을 이용해주세요.",
  "email.exist.facebook": "facebook 간편 가입하기로 가입된 내역이 존재합니다. 간편 로그인을 이용해주세요.",
  "email.exist.kakao": "kakao 간편 가입하기로 가입된 내역이 존재합니다. 간편 로그인을 이용해주세요.",
  "email.exist.kakaosync": "카카오 간편 가입 내역이 존재합니다. (중복)",
  "email.leave": "탈퇴한 이메일입니다. 24시간 후 재가입 가능합니다.",
  "kakao-existed": "'카카오'로 연동되어 있는 계정입니다. 카카오 로그인을 이용해 주세요.",
  "password.check": "영문,숫자,특수문자 조합의 8 ~ 20자 이내로 입력해주세요.",
  "password.no-match": "비밀번호가 일치하지 않습니다.",
  "name.check": "2~15자 이내의 한글,영문,숫자만 입력해주세요.",
  "name.exist": "이미 등록된 닉네임 입니다.",
  "phone.exist": "이미 가입된 번호입니다.",
  "phone.check": "형식에 맞지 않는 번호입니다.",
  "petname.check": "15자 이내의 한글,영문,숫자만 입력해주세요.",
  "yyyymmdd.check": "형식에 맞지 않는 생년월일입니다.",
  "untilToday.check": "정확한 생년월일을 입력해주세요",
  "authNumber.check": "유효하지 않은 인증번호입니다.",
  "repImage.check": "대표 이미지를 등록해주세요.",
  "image.limit": "제한된 용량(10 MB)을 초과하였습니다.",
  "image.ext": "jpg, png, gif 확장자만 첨부 가능합니다.",

  // 안내메시지(alert)
  "cancel": "취소",
  "confirm": "확인",
  "report": "신고",
  "reason": "사유",
  "leave": "나가기",
  "guide": "안내",
  "placeholder.report-reason": "신고 사유를 입력해주세요.",
  "report.type": "신고 유형을 선택해 주세요.",
  "confirm.remove": "삭제된 내역은 복구가 불가능합니다. 삭제하시겠습니까?",
  "confirm.remove.file": "삭제된 파일은 복구가 불가능합니다. 삭제하시겠습니까?",
  "confirm.leave": "사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.",
  "confirm.withdrawal": "탈퇴한 정보는 복구가 불가능합니다. 탈퇴하시겠습니까?",
  "ok.success": "정상적으로 접수되었습니다.",
  "ok.insert": "정상적으로 등록되었습니다.",
  "ok.update": "정상적으로 변경되었습니다.",
  "ok.delete": "정상적으로 삭제되었습니다.",
  "ok.awake": "휴면 상태가 해제되었습니다.",
  "ok.temp": "임시저장이 완료되었습니다.",
  "ok.publish": "발행 신청이 완료되었습니다.",
  "ok.withdrawal": "회원탈퇴가 정상적으로 처리되었습니다.",
  "ok.pet-select": "대표펫 변경이 완료되었습니다",
  "ok.report": "신고가 완료되었습니다.",
  "ok.blinded": "사용자 신고로 블라인드 처리된 게시물입니다.",
  "ok.deleted": "삭제된 게시물입니다.",
  "login.fail": "가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.",
  "login.no-exist": "가입 내역이 존재하지 않습니다.",
  "login.need": "로그인이 필요한 서비스입니다.",
  "phone.no-exist": "가입 내역이 존재하지 않은 휴대폰 번호입니다.",
  "phone.send.email": "입력하신 휴대폰 번호로 아이디가 발송되었습니다.",
  "phone.send.number": "인증번호가 발송되었습니다.",
  "phone.send.fail": "문자 발송 실패, 휴대폰 번호를 다시 확인해주세요.",
  "email.no-exist": "가입 내역이 존재하지 않은 이메일 주소입니다.",
  "email.no-exist2": "존재하지 않는 이메일 주소입니다.",
  "email.send": "입력하신 이메일 주소로 안내 메일이 발송되었습니다.",
  "clipboard.paste": "클립보드에 복사되었습니다.",
  "temp.limit": "임시저장은 최대 30개까지만 가능합니다.",
  "temp.here": "현재 작성 중인 게시글입니다.",
  "temp.here.remove": "현재 작성 중인 문서는 삭제가 불가능합니다.",
  "temp.load": "작성 중인 내용을 임시저장하고 선택한 문서를 불러오시겠습니까?",
  "title.check": "최대 30자까지만 입력가능합니다.",
  "cancel.mypet-register" : "반려동물 등록을 취소하시겠습니까?",
  "save.mypet-register" : "반려동물 등록이 완료되었습니다.",

  // 기타
  "comment.placeholder": "댓글을 입력해 주세요.",
  "reply.placeholder": "답글을 입력해 주세요.",
  "comment.removed": "삭제된 댓글입니다.",
  "comment.hide": "비공개 처리된 내용입니다.",
  "comment.word-filter": "사용할 수 없는 단어({{word}})를 포함하고 있습니다.",

  "nodata": "저장된 내역이 존재하지 않습니다.",
  "nodata2": "조회된 내역이 존재하지 않습니다.",
  "nodata3": "추천 콘텐츠가 존재하지 않습니다.",
  "nodata4": "콘텐츠가 존재하지 않습니다.",

  "join.exist": "이미 등록된 사용자 입니다.",
  "naver.exist": "NAVER로 가입된 아이디 입니다.",
  "facebook.exist": "FACEBOOK으로 가입된 아이디 입니다.",
  "kakao.exist": "KAKAO로 가입된 아이디 입니다.",

  "fail": "요청한 처리가 실패하였습니다.",

  "kakaosync-no-email": "카카오 계정의 이메일 정보가 없습니다.",
  "confirm.kakaosync-connect": "'카카오' 계정 연동 시 기존 회원 정보는 카카오 정보로 변경됩니다. 연동하시겠습니까?",
  "rejoin": "회원가입을 시도하신 계정은 {{date}} 탈퇴한 계정으로 탈퇴 일로부터 24시간 후에 재가입하실 수 있습니다.",
}
