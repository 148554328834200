<template>
  <div class="fix-bottom-menu">
    <router-link to="/">
      <div class="icon-img icon-home"></div>
      <span>홈</span>
    </router-link>

    <a href="https://ggolabshop.co.kr" target="_blank">
      <div class="icon-img icon-store"></div>
      <span>스토어</span>
    </a>

    <a href="#" @click.prevent="mypet">
      <div class="icon-img icon-mypet"></div>
      <span>마이펫</span>
    </a>

    <router-link to="/menu">
      <div class="icon-img icon-menu"></div>
      <span>전체</span>
    </router-link>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
export default {
  data() {
    return {
    }
  },
  computed:{
    ...mapGetters({
      isLogin: 'session/isLogin',
    })
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    mypet(){
      if (!this.isLogin) {
        this.setMsgPopup({
          type: 'alert',
          message: this.$msg('login.need'),
          okay: 'GO_LOGIN',
          okayCallback: () => {
            this.setMsgPopup()
            localStorage.setItem('loginBeforePage', '/mypet/')
            this.$router.push('/login')
          }
        })
      } else{
        this.$router.push('/mypet/')
      }

    }
  }
}
</script>