<template>
    <layout>
        <router-view />
        <confirm-popup v-if="isWeb && getMsgPopup.message" :confirmType="getMsgPopup.type"
                       :onYesClick="getMsgPopup.okayCallback" :onNoClick="getMsgPopup.cancelCallback"
                       :confirmMessage="getMsgPopup.message"/>
    </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Layout from '@/layouts'
import scriptMixin from "@/mixins/scriptMixin"
import jwtMixin from "@/mixins/jwtMixin"
import './assets/scss/_index.scss'

export default {
  mixins: [scriptMixin, jwtMixin],
  data() {
    return {
      transitionName: null,
      isWeb: true,
    }
  },
  components: { Layout, },
  watch: {
    $route(to, from) {
      this.removeLocalStorage()
      document.body.style.overflowY = 'scroll'

      setTimeout(() => {
        if (document.getElementById(`/${to.path.split('/')[1]}`)) {
          document.getElementById(`/${to.path.split('/')[1]}`).scrollIntoView({block: 'center', inline: 'center'}) // , behavior: 'smooth'
        }
      }, 30)

      this.postUrlForReactNative(to.fullPath)
    },
  },
  computed: {
    ...mapGetters({
      getMsgPopup: 'common/getMsgPopup',
    }),
  },
  created() {
    console.log(process.env.NODE_ENV === 'development' ? "[개발]" : "[운영]")

    this.setJwtInfo()
    this.setCommonCode() // 공통 코드
    this.getWordFilter() // 단어 필터링 목록

    if (!this.$isMobileCheck()) {
      if (process.env.NODE_ENV === 'production') {
        location.replace(`https://www.ggolab.co.kr${location.pathname}${location.search}`)
      }
      else {
        location.replace(`https://ggolab.programrush.co.kr${location.pathname}${location.search}`)
      }
    }

    if (window.ReactNativeWebView) {
      const getUserAgent = () => {
        const agent = navigator.userAgent.toLowerCase()
        if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1 || agent.indexOf('ipod') > -1 || agent.indexOf('mac') > -1) {
          return 'ios'
        } else if (agent.indexOf('android') > -1) {
          return 'android'
        } else {
          return 'etc'
        }
      }

      if (getUserAgent() == 'ios') {
        this.isWeb = false
        window.addEventListener('message', ({data}) => {
          const { type, result } = JSON.parse(data)
          if (type === 'back') this.$router.go(-1)
          if (this.getMsgPopup.okay === result) this.getMsgPopup.okayCallback()
          else this.getMsgPopup.cancelCallback()
        })
      } else if (getUserAgent() == 'android') {
        this.isWeb = false
        document.addEventListener('message', ({data}) => {
          const { type, result } = JSON.parse(data)
          if (type === 'back') this.$router.go(-1)
          if (this.getMsgPopup.okay === result) this.getMsgPopup.okayCallback()
          else this.getMsgPopup.cancelCallback()
        })
      }
    }
  },
  methods: {
    ...mapActions({
      setCode: 'common/setCode',
      setMsgPopup: 'common/setMsgPopup',
      setWordFilter: 'common/setWordFilter',
    }),
    async setCommonCode() {
      const { result, data } = await this.$api.getCodeAll()
      if (result === 'success') await this.setCode(data)
    },
    async getWordFilter() {
      const { result, data } = await this.$api.getWordFilter()
      if (result === 'success') this.setWordFilter({ wordFilter: data })
    },
    removeLocalStorage() {
      if (this.$route.path !== '/sleep') localStorage.removeItem('sleep')
      if (this.$route.path !== '/login') localStorage.removeItem('loginBeforePage')
    },
    postUrlForReactNative(url) {
      console.log("> postUrlForReactNative - url : ", url)
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'url', url: url }))
      }
    }
  }
}
</script>