export default {
  install(Vue) {
    Vue.prototype.$getDateFormat = function(date, format, days = 0) {
      if (!date) return null
      let _date = new Date(date.replace(/\-/gi, "/"))

      if (days !== 0) {
        const time = _date.getTime()
        _date = new Date(time + (86400 * days * 1000))
      }

      const year = _date.getYear()
      const month = _date.getMonth() + 1
      const day = _date.getDate()
      let dYear = _date.getFullYear()
      let dMonth = month
      let dDay = day

      if (month.toString().length == 1) dMonth = "0" + month
      if (day.toString().length == 1) dDay = "0" + day

      format = format.replace("Y", dYear)
      format = format.replace("m", dMonth)
      format = format.replace("d", dDay)
      format = format.replace("y", year)
      format = format.replace("n", month)
      format = format.replace("j", day)

      const hours = _date.getHours()
      const min = _date.getMinutes()
      const sec = _date.getSeconds()
      let dHours = hours
      let dMin = min
      let dSec = sec

      if (hours.toString().length == 1) dHours = "0" + hours
      if (min.toString().length == 1) dMin = "0" + min
      if (sec.toString().length == 1) dSec = "0" + sec

      format = format.replace("H", dHours)
      format = format.replace("i", dMin)
      format = format.replace("s", dSec)

      return format
    }

    Vue.prototype.$calcAge = function(yyyymmdd, date= null) {
      const _date = date ? new Date(date) : new Date() // 인자값 date 가 없으면 오늘 날짜
      yyyymmdd = yyyymmdd.toString()
      const diffDate = _date.getTime() - new Date(yyyymmdd.substr(0, 4), (yyyymmdd.substr(4, 2))-1, yyyymmdd.substr(6, 2)).getTime()
      const dateDays = Math.abs(diffDate / (1000 * 3600 * 24)) // 일수
      const months = Math.floor(dateDays / 30) // 개월수
      return `${parseInt(months / 12)}세 ${months % 12}개월`
    }

    Vue.prototype.dateFormat = function(date) {
      date = String(date)
      let year = date.substring(0,4)
      let month = date.substring(4,6)
      let day = date.substring(6)
      let dateFormat = new Date(year,month-1,day)

      return `${dateFormat}`
    }
  }
}
