import axios from 'axios'
import store from '@/store'

export const membersResource = "members"
export const notificationResource = "notice"
export const commonResource = "common"
export const boardResource = "board"
export const myPageResource = "mypage"
export const petsResource = "pets"
export const serviceResource = "service"
export const snsResource = "sns"

export default class api {

    instance = null
    getBaseUrl = null

    constructor() {
        this.getBaseUrl = process.env.NODE_ENV === 'development'
          ? 'https://ggolab-api.programrush.co.kr'
          : 'https://api.ggolab.co.kr'

        this.instance = axios.create({
            baseURL: this.getBaseUrl,
            timeout: 60000,
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/x-www-form-urlencoded charset=UTF-8',
            },
        })
    }

    setInterceptors(instance) {
        instance.interceptors.request.use(
            (config) => {
                store.dispatch('common/setLoading', { isLoading: true })
                if (store.getters['session/getAuthToken']) config.headers.Authorization = `Bearer ${store.getters['session/getAuthToken']}`
                if (store.getters['session/getSleepToken']) config.headers.Authorization = `Bearer ${store.getters['session/getSleepToken']}`
                return config;
            },
            (error) => {
                store.dispatch('common/setLoading', { isLoading: false })
                return Promise.reject(error);
            },
        );
        instance.interceptors.response.use(
            (response) => {
                store.dispatch('common/setLoading', { isLoading: false })
                return response;
            },
            (error) => {
                store.dispatch('common/setLoading', { isLoading: false })
                console.log("=========== ERROR ===========")
                console.log("> error : ", error)
                console.log("> error.response : ", error.response)
                console.log("=========== // ERROR ===========")
                if (error.response && error.response.status === 401) {
                    // console.log('the error response status is:', error.response.status);
                    localStorage.removeItem('auth')
                    localStorage.removeItem('id')
                    localStorage.removeItem('email')
                    localStorage.removeItem('name')
                    localStorage.removeItem('phone')
                    location.href = '/login'
                }
                return Promise.reject(error);
            },
        );
        return instance;
    }

    api = () => this.setInterceptors(this.instance)

    getAPI = (url, payload) => this.api().get(url, { params: payload }).then((res) => res.data)
    postAPI = (url, payload) => this.api().post(url, payload).then((res) => res.data)

    main = () => this.getAPI(`${boardResource}/getMain`)
    getCompanyInfo = () => this.getAPI(`${serviceResource}/getComInfo`)
    getPolicy = (payload) => this.getAPI(`${serviceResource}/getPolicy`, payload)

    /* 로그인 */
    joinEmail = (payload) => this.postAPI(`${membersResource}/joinMember`, payload)
    chkEmail = (payload) => this.postAPI(`${membersResource}/checkEmail`, payload)
    chkName = (payload) => this.postAPI(`${membersResource}/checkNick`, payload)
    chkPhone = (payload) => this.postAPI(`${membersResource}/checkPhone`, payload)
    sendVerifiPhone = (payload) => this.postAPI(`${membersResource}/sendVerifiPhone`, payload)
    checkVerifiPhone = (payload) => this.postAPI(`${membersResource}/checkVerifiPhone`, payload)
    setVerifiExpire = (payload) => this.postAPI(`${membersResource}/checkVerifiexpire`, payload)
    findId = (payload)  => this.postAPI(`${membersResource}/findId`, payload)
    findPw = (payload) => this.postAPI(`${membersResource}/findPw`, payload)
    checkToken = (payload) => this.postAPI(`${membersResource}/checkToken`, payload)
    sleepOff = () => this.postAPI(`${membersResource}/setSleepOff`)
    loginEmail = (payload) => this.postAPI(`${membersResource}/login`, payload)
    loginKakaoSync = (payload) => this.postAPI(`${snsResource}/snsLogin/kakao`, payload)
    checkForKakaoSyncConnect = (payload) => this.postAPI(`${snsResource}/connectCheck`, payload)
    connectKakaoSync = (payload) => this.postAPI(`${snsResource}/kakaoConnect`, payload)
    joinNewSns = (payload) => this.postAPI(`${snsResource}/setMember`, payload)
    // chkSnsNaver = (payload) => this.getAPI(`${snsResource}/snsLogin/naver`, payload)
    // chkSnsFacebook(payload)   { return this.getAPI(`${snsResource}/snsLogin/facebook`, payload) }
    // chkSnsKakao = (payload) => this.getAPI(`${snsResource}/snsLogin/kakao`, payload)
    joinSns = (payload) => this.postAPI(`${membersResource}/joinSnsMamber`, payload)

    /* 검색 */
    getSearchList = (payload) => this.getAPI(`${boardResource}/searchM`, payload)

    /* 알림 */
    getNotificationCount = () => this.getAPI(`${notificationResource}/noticeCount`)
    getNotificationList = (payload) => this.getAPI(`${notificationResource}/getNotice`, payload)
    readNotification = (payload) => this.postAPI(`${notificationResource}/readNotice`, payload)
    removeNotification = (payload) => this.postAPI(`${notificationResource}/deleNotice`, payload)

    /* 게시판 */
    addBoardView = (payload) => this.postAPI(`${boardResource}/setView`, payload)
    getBoardList = (payload) => this.getAPI(`${boardResource}/getBoardList`, payload)
    getBoardDetail = (payload) => this.getAPI(`${boardResource}/getBoardDetail`, payload)
    getMainGgomunityList = () => this.getAPI(`${boardResource}/getMainGgomu`)
    saveGgomunity = (payload) => this.postAPI(`${boardResource}/setGgomu`, payload)
    removeGgomunity = (payload) => this.postAPI(`${boardResource}/removeGgomu`, payload)
    getGgomunityTopicList = () => this.getAPI(`${boardResource}/getTopicList`)
    saveGgomunityTopic = (payload) => this.postAPI(`${boardResource}/setTopic`, payload)
    
    /* 영상 */
    getMdList = () => this.getAPI(`${boardResource}/getMdList`)
    getMdDetail = (payload) => this.getAPI(`${boardResource}/getVideoDetail`, payload)
    getMdListByIdx = (payload) => this.getAPI(`${boardResource}/getMdIdxList`, payload)

    /* 게시판 관련 */
    toggleLike = (payload) => this.postAPI(`${boardResource}/setLike`, payload)
    share = (payload) => this.postAPI(`${boardResource}/setShare`, payload)
    getRecommend = () => this.getAPI(`${boardResource}/getRecommend`)
    getReportType = () => this.getAPI(`${boardResource}/getReportType`)
    setBoardReport = (payload) => this.postAPI(`${boardResource}/setBoardReport`, payload)

    /* 댓글 */
    getCommentList = (payload) => this.getAPI(`${boardResource}/getMCommentList`, payload)
    saveComment = (payload) => this.postAPI(`${boardResource}/setComment`, payload)
    removeComment = (payload) => this.postAPI(`${boardResource}/removeComment`, payload)
    reportComment = (payload) => this.postAPI(`${boardResource}/setCommentReport`, payload)

    /* 마이페이지 */
    getMyBoardList = (payload) => this.getAPI(`${myPageResource}/getMyBoardList`, payload)
    getMyBoardCount = () => this.getAPI(`${myPageResource}/getMyBoardCount`)
    getMylog = (payload) => this.getAPI(`${myPageResource}/getMylog`, payload)
    getMyInfo = () => this.getAPI(`${myPageResource}/getMemberInfo`)
    modify = (payload) => this.postAPI(`${myPageResource}/setMemberInfo`, payload)
    withdrawal = (payload) => this.postAPI(`${myPageResource}/leaveMember`, payload)
    setPassword = (payload) => this.postAPI(`${myPageResource}/setMemberPw`, payload)
    
    /* 마이펫 */
    setPets = (payload) => this.postAPI(`${petsResource}/setPets`, payload)
    getBreed = (payload) => this.getAPI(`${petsResource}/getBreed`, payload)
    getMainPet = (payload) => this.postAPI(`${petsResource}/getMainPet`, payload)
    setMainPet = (payload) => this.postAPI(`${petsResource}/setMainPet`, payload)
    getPetsList = (payload) => this.getAPI(`${petsResource}/getPetsList`, payload)
    getPetsDetail = (payload) => this.getAPI(`${petsResource}/getPetsDetail`, payload)
    removePets = (payload) => this.postAPI(`${petsResource}/removePets`, payload)
    getHealthWeight = (payload) => this.getAPI(`${petsResource}/getHealthWeight`, payload)
    setWeight = (payload) => this.postAPI(`${petsResource}/setWeight`, payload)
    getWeightDetail = (payload) => this.getAPI(`${petsResource}/getWeightDetail`, payload)
    removeWeight = (payload) => this.postAPI(`${petsResource}/removeWeight`, payload)
    getHealtVac = (payload) => this.getAPI(`${petsResource}/getHealtVac`, payload)
    setHealtVac = (payload) => this.postAPI(`${petsResource}/setHealtVac`, payload)
    getHealtVacDetail = (payload) => this.getAPI(`${petsResource}/getHealtVacDetail`, payload)
    removeVac = (payload) => this.postAPI(`${petsResource}/removeVac`, payload)
    getHealtContent = (payload) => this.getAPI(`${petsResource}/getHealtContent`, payload)
    getDisContent = (payload) => this.getAPI(`${petsResource}/getDisContent`, payload)
    setDisease = (payload) => this.postAPI(`${petsResource}/setDisease`, payload)

    /* 고객센터 */
    getEventList = (payload) => this.getAPI(`${serviceResource}/getEventList`, payload)
    getEventDetail = (payload) => this.getAPI(`${serviceResource}/getEventDetail`, payload)
    getNoticeList = (payload) => this.getAPI(`${serviceResource}/getMNoticeList`, payload)
    getNoticeDetail = (payload) => this.getAPI(`${serviceResource}/getMNoticeDetail`, payload)
    getInquiryList = (payload) => this.getAPI(`${serviceResource}/getInquiryList`, payload)
    getInquiryDetail = (payload) => this.getAPI(`${serviceResource}/getInquiryDetail`, payload)
    saveInquiry = (payload) => this.postAPI(`${serviceResource}/setInquiry`, payload)
    removeInquiry = (payload) => this.postAPI(`${serviceResource}/removeInquiry`, payload)
    getFaqList = (payload) => this.getAPI(`${serviceResource}/getMFaqList`, payload)
    getFaqDetail = (payload) => this.getAPI(`${serviceResource}/getMFaqDetail`, payload)


    /* 공통 */
    getCate = (payload) => this.getAPI(`${commonResource}/getCateCode`, payload)
    getScript = (payload) => this.getAPI(`${commonResource}/getScript`, payload)
    getWordFilter = () => this.getAPI(`${commonResource}/getWordFilterList`)
    getMainProductCtgry = (payload) => this.getAPI(`${commonResource}/getMainProductCate`, payload)
    getMainProductList = (payload) => this.getAPI(`${commonResource}/getMainProductList`, payload)
    getBanner = (payload) => this.getAPI(`${commonResource}/getBanner`, payload)
    getCodeAll = (payload) => this.postAPI(`${commonResource}/getAllCode`, payload)
    removeAttachFile = (payload) => this.postAPI(`${commonResource}/setAttachFileRemove`, payload)
}