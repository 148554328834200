const common = {
    namespaced: true,
    state: {
        isLoading: false,
        wordFilter: null,
        code: {
            keyword: [],
            disease: [],
            allergy: [],
        },
        msgPopup: {
            type: null,
            title: null,
            message: null,
            okay: null,
            okayCallback: null,
            cancel: null,
            cancelCallback: null,
        },
        mainPetId: null,
    },
    mutations: {
        MUT_SET_LOADING(state, payload) {
            state.isLoading = payload.isLoading
        },
        MUT_SET_WORD_FILTER(state, payload) {
            state.wordFilter = payload.wordFilter
        },
        MUT_SET_CURRENT_PATH(state, payload) {
            state.currentPath = payload
        },
        MUT_SET_CODE(state, payload) {
            state.code.keyword = payload.codes[1010]
            state.code.disease = payload.codes[1011]
            state.code.allergy = payload.codes[1012]
        },
        MUT_SET_MSG_POPUP(state, payload) {
            if (payload) {
                state.msgPopup = {
                    ...state.msgPopup,
                    ...payload
                }
            }
            else {
                state.msgPopup = {
                    type: null,
                    title: null,
                    message: null,
                    okay: null,
                    okayCallback: null,
                    cancel: null,
                    cancelCallback: null,
                }
            }

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify(state.msgPopup))
            }
        },
        MUT_SET_MAINPETID(state, payload) {
            state.mainPetId = payload
        },
    },
    actions: {
        setLoading({commit}, payload) {
            commit("MUT_SET_LOADING", payload)
        },
        setWordFilter({commit}, payload) {
            commit("MUT_SET_WORD_FILTER", payload)
        },
        setCode({commit}, payload) {
            commit("MUT_SET_CODE", payload)
        },
        setMsgPopup({commit}, payload) {
            commit("MUT_SET_MSG_POPUP", payload);
        },
        setMainPetId({commit}, payload) {
            commit("MUT_SET_MAINPETID", payload);
        },
    },
    getters: {
        isLoading: state => {
            return state.isLoading
        },
        getWordFilter: state => {
            return state.wordFilter
        },
        getKeywordCode: state => {
            return state.code.keyword
        },
        getDiseaseCode: state => {
            return state.code.disease
        },
        getAllergyCode: state => {
            return state.code.allergy
        },
        getMsgPopup: state => {
            return state.msgPopup
        },
        getMainPetId: state => {
            return state.mainPetId
        },
    },
}

export default common