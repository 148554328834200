import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import common from '@/store/modules/common.js'
import session from '@/store/modules/session.js'
import verify from '@/store/modules/verify.js'

const store = new Vuex.Store({
    modules: {
        common: common,
        session: session,
        verify: verify,
    }
})

export default store