<template>
  <div id="app" class="wrap">
    <slot/>
    <globalFooter/>
  </div>
</template>
<script>
import GlobalFooter from "@/components/common/Footer"
export default {
  components: {GlobalFooter},
  data() {
    return {
    }
  },
}
</script>