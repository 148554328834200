<template>
  <div v-if="msg" class="tost-msg">{{msg}}</div>
</template>

<script>
export default {
    data() {
        return {
            msg: null,
        }
    },
    methods: {
      show(msg) {
        this.msg = msg
        setTimeout(()=> {
          this.msg = null
        },1000)
      },
    }
}
</script>

<style scoped>
.toast-view-enter {margin-bottom: -60px; opacity: 0;}
.toast-view-leave-to {transform: scale(0); opacity: 0;}
.toast-view-enter-active {transition: all .3s ease-out;}
.toast-view-leave-active {transition: all .2s ease-out;}
</style>