const verify = {
    namespaced: true,
    state: {
        phone: {
            url: null,
            phone: null,
            isAgree: null,
            sns: null,
            snsToken: null,
            email: null,
            name: null,
        }
    },
    mutations: {
        MUT_SET_PHONE(state, payload) {
            if (payload) {
                if (payload.hasOwnProperty('url')) state.phone.url = payload.url
                if (payload.hasOwnProperty('phone')) state.phone.phone = payload.phone
                if (payload.hasOwnProperty('isAgree')) state.phone.isAgree = payload.isAgree
                if (payload.hasOwnProperty('sns')) state.phone.sns = payload.sns
                if (payload.hasOwnProperty('snsToken')) state.phone.snsToken = payload.snsToken
                if (payload.hasOwnProperty('email')) state.phone.email = payload.email
                if (payload.hasOwnProperty('name')) state.phone.name = payload.name
            }
            else {
                state.phone = {
                    url: null,
                    phone: null,
                    isAgree: null,
                    sns: null,
                    snsToken: null,
                    email: null,
                    name: null,
                }
            }
        },
    },
    actions: {
        setPhone({commit}, payload) {
            commit("MUT_SET_PHONE", payload)
        },
    },
    getters: {
        getPhone: state => {
            return state.phone
        },
    },
}

export default verify