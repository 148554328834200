<template>
  <div  class="bottom-sheet-layer">
    <div class="layer-bg" @click="close"></div>
    <div class="bottom-sheet-inner">
        <div class="bottom-sheet-header">
          <div class="close" @click="close">닫기</div>
          <div>대표펫</div>
          <router-link to="/mypet" class="right-box">펫관리</router-link>
      </div>

      
      <div class="mypet-list">
        <label v-for="(item, index) in list" :key="`pet-${index}`">
          <input type="radio" name="mypet" v-model="mypetName"
          :class="[{on : item.isMain == 1}, {active : item.idx == idx}]" 
          @click="mypetChange(item.idx)">
          <div class="myPetProfile">
            <div class="profile-img">
              <div class="profile-img-bg">
                <image-alt v-show="item.thumbnail" :src="item.thumbnail" altType="profile"/>
              </div>
            </div>
            <div class="petname">{{item.name}}</div>
          </div> 
        </label>
        
      </div>
      
      <div class="sheet-btn">
        <button @click="save" :disabled="!isChange" >대표펫 변경</button>
      </div>
    </div>    
  </div>    
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      mypetName:null,
      list:null,
      idx :null,
    }
  },
  created(){
    this.getList()
  },
  computed: {
    ...mapGetters({
      getUrl: 'session/getUrl',
    }),
    isLoginStatus() {
      return this.isLogin;
    },
    isChange() {
      return this.idx ? true : false
    },
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
      setMainPetId: 'common/setMainPetId',
    }),
    mypetChange(idx){
      this.idx = idx
    },
    async save() {
      const { result } = await this.$api.setMainPet({ petIdx: this.idx })
      if (result === 'success') {
        $toast(this.$msg('ok.pet-select'))
        this.setMainPetId(this.idx)
        this.$emit('save')
      }
    },
    close(){
      this.$emit('close')
    },
    async getList() {
      const { result, list } = await this.$api.getPetsList({ petCode: this.pet })
      if (result === 'success') this.list =  list
    },
  }
    
}
</script>