<template>
  <component :is="layout">
    <slot/>
  </component>
</template>
<script>
import Default from '@/layouts/Default.vue'
import NoHeader from '@/layouts/NoHeader.vue'
import NoLayout from '@/layouts/NoLayout.vue'
import MyPet from '@/layouts/MyPet.vue'

const defaultLayout = 'Default'
export default {
  components: { Default, NoHeader, NoLayout, MyPet },
  computed: {
    layout() {
      // 중첩라우터 사용 시 레이아웃 동적 호출 X -> 정적 호출로 변경함.
      const layout = this.$route.meta.layout || defaultLayout
      return layout
      // return () => import(`@/layouts/${layout}.vue`)
    }
  }
}
</script>