import {mapActions} from "vuex";

export default {
  methods:{
    ...mapActions({
      setSession: 'session/setSession',
    }),
    setJwtInfo() {
      const token = localStorage.getItem('auth')
      this.setJwt(token)
    },
    setJwt(token) {
      if (token) {
        const params = {
          authToken: token,
          id: localStorage.getItem('id'),
          email: localStorage.getItem('email'),
          name: localStorage.getItem('name'),
          url: localStorage.getItem('url'),
          target: localStorage.getItem('target'),
          phone: localStorage.getItem('phone'),
        }
        this.setSession(params)
      }
    },
  }
}
