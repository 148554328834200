<template>
  <div>
    <div class="layout-sub-header">
      <div class="history-back" @click="$router.push('/mypet')">이전페이지</div>
      건강수첩
      <router-link to="/" class="main-home">홈</router-link>
    </div>

    <div class="layout-lnb" >
      <div class="cate-dep2">
        <div v-for="(item, index) in chart" :key="`chart${index}`" :class="{ on : $route.name === `mypet/chart${item.value}` }"
             @click="$router.push(`/mypet/chart${item.value}/${idx}`)">{{ item.text }}</div>
      </div>
    </div>
    <div class="header-empty2"></div>

    <slot/>

  </div>
</template>
<script>
export default {
  data() {
    return {
      menuName: null,
      idx: null,
      chart: [
        { text: '몸무게', value: 1 },
        { text: '접종현황', value: 2 },
        { text: '콘텐츠', value: 3 },
      ],
    }
  },
  created() {
    this.menuName =  '건강수첩'
    this.idx = this.$route.params.idx
  },
  methods: {
  }
}
</script>