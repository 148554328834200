import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  data: function () {
    return {
      settings: {
        "arrows": false,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "dots": true,
        "swipeToSlide" : true,
        "centerPadding": '17px',
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": true,
      },
      topBannerSettings: {
        "arrows": false,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "slidesToScroll": 1,
        "dots": false,
        "swipeToSlide" : true,
        "vertical": true,
        // "fade":true,
      },
      bannerSettings: {
        "arrows": true,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "slidesToScroll": 1,
        "dots": true,
        "swipeToSlide" : true
      },
      ggomunityBannerSettings: {
        "arrows": false,
        "infinite": true,
        "initialSlide": 0,
        "slidesToScroll": 1,
        "dots": true,
        "swipeToSlide" : true,
        "adaptiveHeight": true,
      },
    }
  },
  components: { VueSlickCarousel },
  methods:{
    setHeightForVerticalSlide() {
      if (document.getElementsByClassName("vertical").length === 0) return false
      const verticalSlide = document.getElementsByClassName("vertical")[0].getElementsByClassName("slick-slide")
      if (verticalSlide.length > 1) {
        let maxHeight = -1;
        verticalSlide.forEach((slide) => {
          const slideHeight = slide.clientHeight
          if (slideHeight > maxHeight) {
            maxHeight = slideHeight;
          }
        });
        verticalSlide.forEach((slide) => {
          const slideHeight = slide.clientHeight
          if (slideHeight < maxHeight) {
            slide.style.margin = Math.ceil((maxHeight-slideHeight)/2) + 'px 0';
          }
        });
      }
    },
  }
}
